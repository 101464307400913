<template>
  <div class="travel-tip-detail-page" :class="{'day-nav-pb': travelTip && travelTip.itineraryDays && travelTip.itineraryDays.length > 1 && activeNavItem === 'ITINERARY'}">
    <div id='header-container'>
      <div class='tr-container'>

        <div v-if="!isLoadingTravelTip && travelTip" id="main-jumbo" class="jumbotron jumbotron-fluid">
          <div class="tr-container" style="overflow: visible; position:relative;">
            <a type='button' class='btn noapearance' id='take-it' @click="$router.push({path: '/search'})">
              <svg width="14" height="23" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5625 21.125L2.5625 11.125L12.5625 1.125" stroke="#222222" stroke-width="3"/>
              </svg>
            </a>
            <h1 :class="titleShadow">{{ travelTipSingleLocalization.title ? travelTipSingleLocalization.title : $t("admin.hereWillBe", [this.$t("inputLabels.travelTipTitle")]) }}</h1>
            <h4>
              <span :style="this.shortInfoBackground">{{ travelTipSingleLocalization.shortInfo ? travelTipSingleLocalization.shortInfo : $t("admin.hereWillBe", [this.$t("inputLabels.travelTipShortInfo")]) }}</span>
            </h4>
          </div>
        </div>
      </div>
      <section v-if="!isLoadingTravelTip && travelTip" class="glasses-block" v-bind:style="{ 'background-image': 'url(' + backgroundImageUrl + ')' }">
        <img v-if="travelTip.theme === 'TOURISM'" class="absolute-animated" src="@/assets/svg/clipedBigger-detail.svg"
             alt="glasses">
        <img v-if="travelTip.theme === 'CULTURE'" class="absolute-animated" src="@/assets/svg/clipedBigger-detail-orange.svg"
             alt="glasses" @click="searchPageRedirect('theme', 'CULTURE')">
        <img v-if="travelTip.theme === 'SPORT'" class="absolute-animated" src="@/assets/svg/clipedBigger-detail-blue.svg"
             alt="glasses" @click="searchPageRedirect('theme', 'SPORT')">
        <img v-if="travelTip.theme === 'FOOD'" class="absolute-animated" src="@/assets/svg/clipedBigger-detail-pink.svg"
             alt="glasses" @click="searchPageRedirect('theme', 'FOOD')">
      </section>
    </div>
    <div class="error-container" v-if="isLoadingTravelTip">
      <p>{{ $t("loading.loading") }}</p>
    </div>
    <div class="error-container" v-else-if="!travelTip">
      <p>{{ $t("public.travelTipDetailPage.travelTipTranslationUnavailable")}}</p>
    </div>
    <div id="trip-nav" class="navigation-background">
      <div class="trip-navigation tr-container">
        <button @click="setActiveNavItem('SUMMARY')" :class="{active: activeNavItem === 'SUMMARY'}">
          <div class="icon info-icon"></div>
          <p>{{ $t("public.travelTipDetailPage.summary") }}</p>
        </button>
        <button @click="setActiveNavItem('ITINERARY')" :class="{active: activeNavItem === 'ITINERARY'}">
          <div class="icon itinerary-icon"></div>
          <p>{{ $t("public.travelTipDetailPage.itinerary") }}</p>
        </button>
        <button @click="setActiveNavItem('EQUIPMENT')" :class="{active: activeNavItem === 'EQUIPMENT'}" disabled v-tooltip.right="$t('info.disabledInPreview')">
          <div class="icon equipment-icon"></div>
          <p>{{ $t("general.equipments") }}</p>
        </button>
        <button @click="setActiveNavItem('GALLERY')" :class="{active: activeNavItem === 'GALLERY'}">
          <div class="icon gallery-icon"></div>
          <p>{{ $t("public.travelTipDetailPage.gallery") }}</p>
        </button>
        <button @click="setActiveNavItem('ASSESSMENTS')" :class="{active: activeNavItem === 'ASSESSMENTS'}" disabled v-tooltip.right="$t('info.disabledInPreview')">
          <div class="icon assessment-icon"></div>
          <p>{{ $t("general.assessments") }}</p>
        </button>
        </div>
    </div>
    <div>
      <div class="error-container" v-if="isLoadingTravelTip">
        <p>{{ $t("loading.loading") }}</p>
      </div>
      <div class="error-container" v-else-if="!travelTip">
        <p>{{ $t("public.travelTipDetailPage.travelTipTranslationUnavailable")}}</p>
      </div>
      <main id='main-container' class="tr-container mt-5" v-else>
          <section v-if="activeNavItem === 'SUMMARY'" class='row'>
            <div class='col-12 col-md-5 col-xl-4 mb-5'>
              <div class="summary-container">
                <div class="summary-row">
                  <p class="summary-row-title">{{ $t("general.countries") }}</p>
                  <p class="summary-row-content">{{ countriesNames }}</p>
                </div>
                <div class="summary-row">
                  <p class="summary-row-title">{{ $t("general.length") }}</p>
                  <p class="summary-row-content">{{ getLengthOfTrip() }} km</p>
                </div>
                <div v-for="category in tagCategoriesFiltered" :key="category.id" class="summary-row">
                  <p class="summary-row-title" >{{ category.localizations[0].title }}</p>
                  <div class="tags-container">
                    <span class="summary-row-content tag pr-2" v-for="tag in category.tags" :key="tag.id">{{ tag.localizations[0].title }}</span>
                  </div>
                </div >
                <div class="summary-row">
                  <div class="d-flex align-items-center" style="gap: 5px">
                    <p class="summary-row-title">{{ $t("general.difficulty") }}</p>
                    <div class="info-icon" role="button" @click="$bvModal.show('difficulty-detail-modal')"></div>
                  </div>
                  <p class="summary-row-content">{{ getDifficulty() }}</p>
                </div>
                <ModalDifficultyInfo />
              </div>
            </div>
            <div class="col-12 col-md-7 offset-xl-1 col-xl-7">
              <div class="mb-4">
                <p class="tr__h3 font-emilio">{{ $tc("general.dayAmmount", travelTip.itineraryDays && travelTip.itineraryDays.length) }}</p>
              </div>
              <article>
                <p class="travel-tip-description" style="font-weight: 600">{{ travelTipSingleLocalization.perex ? travelTipSingleLocalization.perex : $t("admin.hereWillBe", [this.$t("inputLabels.travelTipPerex")]) }}</p>
                <p class="travel-tip-description">{{ travelTipSingleLocalization.description ? travelTipSingleLocalization.description : $t("admin.hereWillBe", [this.$t("inputLabels.travelTipDescription")]) }}</p>
                <div>
                  <p style="margin-block: 30px 6px;"><b>{{ $t("inputLabels.practicalInfo") }}</b></p>
                  <p v-html="practicalInfoWithUrls"></p>
                </div>
              </article>
            </div>
            <div id="about-author" class="col-12" style="margin-block: 50px;" v-if="travelTip && travelTip.author && travelTip.author.isAuthorPublished">
              <AboutAuthor :author="travelTip.author" :windowResized="windowResized"/>
            </div>
        </section>
  
        <ItineraryDayListAdminPreview v-else-if="activeNavItem === 'ITINERARY'" :itineraryDays='travelTipSingleLocalization.itineraryDays'/>
        <Gallery v-else-if="activeNavItem === 'GALLERY'" :pictures="allPicturesInTravelTip"/>

        <div class="travel-tip-detail-page-modal-backdrop" :style="{visibility: isMapModalVisible ? 'visible' : 'hidden'}" @click.self="hideMapModal">
          <Map :travelTip="travelTip" :showMap="showMap" @hideMapModal="hideMapModal"/>
        </div>
      </main>
  </div>
  <button class="floating-button map-icon" @click="showMapModal"></button>
  <div class="to-top-arrow" @click="scrollToTop"></div>
</div>
  
</template>
  
<script>
    import {TRAVEL_TIP_BY_ID_ADMIN} from "@/api/graphql/query/TravelTipQuery";
    import ModalDifficultyInfo from "@/components/ModalDifficultyInfo.vue";
    import {CLEVERTRIP_IMG_URL} from '@/definitions';
    import Map from "@/components/Map.vue";
    import AboutAuthor from "@/components/AboutAuthor.vue";
    import sharedUtils from "@/utils/sharedUtils";
    import ItineraryDayListAdminPreview from "@/components/admin/ItineraryDayListAdminPreview.vue";
    import { assignTravelTipLocalizationProperties } from "../../utils/LocalizationDataConverter";
    import Gallery from "../../components/Gallery.vue";
  
    export default {
        name: "AdminTravelTipPreviewPage",

        components: {
        ModalDifficultyInfo,
        Map,
        AboutAuthor,
        ItineraryDayListAdminPreview,
        Gallery
    },

    computed: {
      practicalInfoWithUrls() {
        if (!this.travelTipSingleLocalization.practicalInfo) return this.$t("admin.hereWillBe", [this.$t("inputLabels.practicalInfo")])
        return sharedUtils.convertStringWithUrls(this.travelTipSingleLocalization.practicalInfo)
      },
      tagCategoriesFiltered() {
        if (!this.travelTip) return
          return this.travelTip.tagCategories.map(tagCategory => {
            return {
              ...tagCategory,
              localizations: tagCategory.localizations.filter(loc => {
                return loc.language === this.$store.getters.preferredLanguage && loc.title
              }),
              tags: tagCategory.tags.map(tag => {
                return {
                  ...tag,
                  localizations: tag.localizations.filter(loc => {
                    return loc.language === this.$store.getters.preferredLanguage && loc.title 
                  })
                }
              }).filter(tag => {
                return tag.localizations.length
              })
            }
          }).filter(tagCategory => {
            return tagCategory.localizations.length && tagCategory.tags.length
          })
      },
      travelTipSingleLocalization() {
        return assignTravelTipLocalizationProperties(this.travelTip, this.travelTip.originalLanguage)
      },
      backgroundImageUrl() {
        if (!this.travelTip?.titlePicture?.fileName) return
        let fileName = this.travelTip.titlePicture.fileName 
        return this.imgUrl + fileName.slice(0, fileName.lastIndexOf('.')) + '-dimensions-1600pxX1200px' + fileName.slice(fileName.lastIndexOf('.'))
      },
      titleShadow() {
        if (this.travelTip.theme === 'TOURISM') return `tourism`
        if (this.travelTip.theme === 'CULTURE') return `culture`
        if (this.travelTip.theme === 'SPORT') return `sport`
        if (this.travelTip.theme === 'FOOD') return `food`
        return 'default'
      },
      shortInfoBackground() {
        if (this.travelTip.theme === 'TOURISM') return `background-color: #EFFF36`
        if (this.travelTip.theme === 'CULTURE') return `background-color: #FF8515`
        if (this.travelTip.theme === 'SPORT') return `background-color: #36FFF3`
        if (this.travelTip.theme === 'FOOD') return `background-color: #FA34FE`
        return 'background-color: #3BEA7E'
      },
      countriesNames() {
        let countriesNamesInTrip = []

        if (this.travelTip) {
          if (!this.travelTip.itineraryDays) return []
          this.travelTip.itineraryDays.forEach(day => {
            day.itineraryItems.forEach(item => {
              item.countries.forEach(country => {
                if (!countriesNamesInTrip.some(c => c === country[this.countryLocalization])) {
                  countriesNamesInTrip.push(country[this.countryLocalization])
                }
              })
            })
          })
        }
        return countriesNamesInTrip.join(', ')
      },
      countryLocalization() {
        return this.$store.getters.preferredLanguage == "en" ? "nameEnglish" : "nameCzech"
      }
    },
  
    watch: {
      '$route.params.travelTipId'(nV) {
        this.travelTipId = nV
        this.hideMapModal();
      },
      travelTip(nV) {
        if (nV) {
          this.setAllPicturesInTravelTip(nV)
        }
      },
    },

    created() {
      this.travelTipId = this.$route.params.travelTipId
    },
  
    data() {
      return {
        travelTipId: null,
        imgUrl: CLEVERTRIP_IMG_URL,
        travelTip: Object,
        activeNavItem: "SUMMARY",
        isMapModalVisible: false,
        showMap: null,
        windowResized: false,
        isLoadingTravelTip: true,
        allPicturesInTravelTip: [],
      }
    },
  
    mounted: function () {
      this.throttledResizeHandler = sharedUtils.debounce(() => {
        this.checkViewportWidth();
      }, 250);
  
      const cookiebotWidgetEl = document.getElementById("CookiebotWidget") 
      if (cookiebotWidgetEl) cookiebotWidgetEl.style.bottom = window.innerWidth > 768 ? "60px" : "40px"
      window.addEventListener('scroll', this.showButtonToTop)
      window.addEventListener('resize', this.throttledResizeHandler)
      /*eslint-disable*/
      Loader.async = true
      Loader.load(null, null);
      Loader.lang = "cs";
      /*eslint-enable*/
    },
    destroyed: function() {
      const cookiebotWidgetEl = document.getElementById("CookiebotWidget") 
      if (cookiebotWidgetEl) cookiebotWidgetEl.style.bottom = "10px"
      window.removeEventListener('scroll', this.showButtonToTop)
      window.removeEventListener('resize', this.throttledResizeHandler)
    },
  
    methods: {
      setAllPicturesInTravelTip(travelTip) {
        const allPicturesInTravelTip = []
        if (travelTip.titlePicture) {
          allPicturesInTravelTip.push(travelTip.titlePicture)
        }
        travelTip.itineraryDays.forEach(day => {
          day.itineraryItems.forEach(item => {
            item.itineraryItemPictures.forEach(itemPicture => {
              if (!allPicturesInTravelTip.find(picture => picture.id === itemPicture.id)) {
                allPicturesInTravelTip.push(itemPicture)
              }
            })
          })
        })
        this.allPicturesInTravelTip =  allPicturesInTravelTip
      },
      setActiveNavItem(item) {
        this.activeNavItem = item
        const element = document.getElementById("trip-nav")
        const currentTop = element.getBoundingClientRect().top;

        if (currentTop == 0) {
          const headerElement = document.getElementById("header-container")
          headerElement.scrollIntoView({behavior: "smooth", block: "end"})
          const elementBottom = headerElement.getBoundingClientRect().bottom;

          window.scrollBy({
          top: elementBottom,
          behavior: 'smooth'
        });

          window.scrollBy
        }
      },
      checkViewportWidth() {
        this.windowResized = !this.windowResized
      },
      showButtonToTop() {
        if (this.throttleTimer) return;
          this.throttleTimer = true;
          setTimeout(() => {
              const scrollButton = document.querySelector('.to-top-arrow')
              if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
                scrollButton.classList.add("show");
              } else {
                scrollButton.classList.remove('show');
              }
              this.throttleTimer = false;
            }, 100);
      },
      showMapModal: function () {
        this.showMap = true
        this.isMapModalVisible = true;
      },
      hideMapModal: function () {
        document.body.style.overflow = ""
        this.isMapModalVisible = false;
        this.showMap = false
      },
  
      getLengthOfTrip: function () {
        let length = 0;
        if(!this.travelTip.itineraryDays) return
        for (const itineraryDay of this.travelTip.itineraryDays) {
          for (const itineraryItem of itineraryDay.itineraryItems) {
            for (const transferType of itineraryItem.transferTypeValues) {
              length = length + transferType.length;
            }
          }
        }
        return Math.round(length)
      },
  
      getDifficulty() {
        return sharedUtils.getDifficultyTextFromTravelTip(this.travelTip)
      },
  
      scrollToTop() {
        window.scrollTo({top: 0, behavior: 'smooth'})
      },
    },
  
    apollo: {
        travelTip: {
            query: TRAVEL_TIP_BY_ID_ADMIN,

            variables: function() {
                return {
                    travelTipId: this.travelTipId
                }
            },
            error(error) {
                console.log(error)
                this.isLoadingTravelTip = false
            },

            skip: function() {
              return !this.travelTipId
            },

            result() {
                this.isLoadingTravelTip = false
                this.scrollToTop();
            }
        },
    },
  }
  </script>
  
  <style scoped lang="scss">
      @import '@/scss/variables';

      .travel-tip-detail-page {
  background: $tr-white-alt;
  text-align: left;

  .navigation-background {
    background-color: $tr-white;
    border-bottom: 1px solid $tr-gray;
    position: sticky;
    z-index: 1033;
    top: 0;
  }

  .trip-navigation {
    display: flex;
    gap: 50px;
    overflow-x: auto;
    background-color: $tr-white;
    margin-top: 700px;

    @media (max-width: 768px) {
      margin-top: 520px;
      padding-inline: 15px;
      gap: 30px;
      max-width: unset;
    }
    
    button {
      display: flex;
      align-items: center;
      gap: 10px;
      background: none;
      border: none;
      padding-block: 27px;
      opacity: 0.4;

      @media (max-width: 768px) {
        padding-block: 17px;
      }

      &.active {
        opacity: 1
      }
    }

    .icon {
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
    }

    .itinerary-icon {
      background-image: url('data:image/svg+xml,<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)"><circle cx="6" cy="5" r="1" fill="%23222222"/><circle cx="9" cy="5" r="1" fill="%23222222"/><circle cx="12" cy="5" r="1" fill="%23222222"/><circle cx="15" cy="5" r="1" fill="%23222222"/><circle cx="18" cy="5" r="1" fill="%23222222"/><path d="M19 7v11H5V7m0 2h14M7 12h2m-2 3h2m2-3h2m-2 3h2m2-3h2m-2 3h2" stroke="%23222222"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>');
    }

    .equipment-icon {
      background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 6.5V4a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2.5m-8 0h8m-8 0H6.5m9.5 0h1.5m-11 0H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h2.5m0-14v14m0 0h11m0-14H20a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-2.5m0-14v14" stroke="%23222222"/></svg>');
    }

    .gallery-icon {
      background-image: url('data:image/svg+xml,<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)" stroke="%23222222"><path d="M4 21V4h16.5v17z"/><path d="m9 11.5-2 6h11l-3-4-3.5 2z"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>');
    }

    .assessment-icon {
      background-image: url('data:image/svg+xml,<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)"><path d="m12 4 2.351 4.764 5.257.764-3.804 3.708.898 5.236L12 16l-4.702 2.472.898-5.236-3.804-3.708 5.257-.764z" stroke="%23222222"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>');
    }

  }

  .floating-button {
    width: 70px;
    height: 70px;
    z-index: 1034;
    position: fixed;
    right: 30px;
    bottom: 150px;
    background-size: 40px;
    background-color: $tr-white;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) !important;
    border: none;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    @media (max-width: 576px) {
      right: 10px;
      width: 50px;
      height: 50px;
      bottom: 100px;
      background-size: 30px;
    }

    &.map-icon {
      background-image: url('data:image/svg+xml,<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)"><circle cx="17" cy="18" r="1" fill="%23222222"/><path d="m12.707 6 4.561 1.974L21 6v13.026L17.268 21l-4.56-1.974L8.145 21 4 19.026V6l4.5 1.974" stroke="%23222222"/><path d="M12.5 6c0 .29-.14.72-.413 1.251a13 13 0 0 1-.993 1.578A25 25 0 0 1 10 10.233l-.084-.1a25 25 0 0 1-1.01-1.304 13 13 0 0 1-.993-1.578C7.64 6.72 7.5 6.29 7.5 6a2.5 2.5 0 0 1 5 0Z" stroke="%23222222"/><circle cx="10" cy="13" r="1" fill="%23222222"/><circle cx="10" cy="6" r="1" fill="%23222222"/><path d="M8 21v-9.5m5 7.5V6m4 10V8" stroke="%23222222"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>');
    }
    
    &:focus-visible {
      border: 1px solid $tr-black;
    }

    &:active {
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.5) !important;
    }

}

  &.day-nav-pb {
    padding-bottom: 50px;

    @media (max-width: 768px) {
      padding-bottom: 30px;
    }
  }

}

#take-it {
  position: absolute;
  top: 25px;
  left: 5px;
  padding: 0;
}

main {
  & > * {
    min-height: 60vh;
  }
}

.campaign-carousel-wrapper {
  margin-top: 150px;
  overflow: hidden;
}


#main-jumbo {
  background-repeat: no-repeat;
  background-color: transparent;
  margin-top: 200px;
  max-width: 1000px;
  position: relative;
  z-index: 1;
}

#main-jumbo h1 {
  font-weight: 900;
  font-size: 85px;
  line-height: 0.9;
  position: relative;
  z-index: 1;
  margin-left: 15px;
  text-transform: capitalize;
  word-break: break-word;

  &.culture {text-shadow: 3px 3px $tr-orange;}
  &.tourism {text-shadow: 3px 3px $tr-yellow;}
  &.sport {text-shadow: 3px 3px $tr-blue;}
  &.food {text-shadow: 3px 3px $tr-pink;}
}

#main-jumbo h4 {
  margin-left: 23px;
  margin-top: 10px;
  
  span {
    padding: 5px 10px;
    line-height: 25px;
    word-break: break-word;
  }
}

.summary-container {
  background-color: $tr-white;
  padding: 20px;
  filter: drop-shadow(rgba(0, 0, 0, 0.05) 0px 0px 10px);

  .summary-row {
    display: flex;
    flex-direction: column;
    padding-block: 16px;
    border-bottom: 1px solid $tr-light-gray;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }
  
    .summary-row-title {
      color: $tr-gray;
      font-size: 15px;
      margin-bottom: 0;
    }
  
    .summary-row-content {
      margin-top: 5px;
      padding-right: 20px;
    }
  }
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0 5px;

  .tag {
    padding: 5px;
    background-color: $tr-white-alt;
    border: 1px solid $tr-black;
    line-height: 1;
    display: flex;
    align-items: center;
  }
}


#header-container {
  position: absolute;
  width: 100%;
  height: 800px;
  left: 0px;
  top: 0px;
}

#main-container {
  color: $tr-black;
  margin-top: 620px;
}

.error-container {
  min-height: 100vh;
  padding-top: 60px;
  
  p {
    text-align: center;
    font-size: 20px;
  }
}

.row {
  justify-content: center;
}

.travel-tip-description {
  white-space: pre-wrap;
  margin-top: 10px;
}

.travel-tip-detail-page-modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1035;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-container {
  columns: 260px;
  gap: 30px;

  .img-container {
    width: 100%;
    margin-bottom: 30px;

    img {
      max-width: 100%;
    }
  }
}

.glasses-block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.glasses-block .absolute-animated{
      position: absolute;
      width: 400vw;
      height: auto;
      top: -18vw;
      left: -16vw;
      animation-name: glasses-smallest;
      animation-duration: 30s;
      animation-iteration-count: infinite;

      @media (max-width: 420px) {
        margin-left: -20vw;
      }


      @media (min-width: 420px) {
        left: -51vw;
        animation-name: glasses-small;
        width: calc(500px + 250vw);
        margin-left: -50vw;
      }
      
      @media (min-width: 500px) {
        margin-left: -37vw;
      }

      @media (max-width: 768px) {
        margin-top: -20vw;
      } 
      @media (min-width: 768px) {
        margin-left: 0;
        top: -20vw;
        left: -51vw;
        width: 250vw;
        height: auto;
        animation-name: glasses-tall;
      }
      @media (min-width: 1200px) {
        top: -20vw;
        left: -51vw;
        animation-name: glasses;
        width: 250vw;

      }

      @media (min-width: 1650px) {
        top: -30vw;
        left: -60vw;
        width: 210vw;
        animation-name: glasses-xl;
        margin-top: 0;
      }
    }

    @keyframes glasses-smallest {
      0%   {left:-120vw; top:-57vw;}
      20%  {left:-126vw; top:-80vw;}
      40%  {left:-132vw; top:-110vw;}
      60%  {left:-137vw; top:-85vw;}
      80%  {left:-108vw; top:-74vw;}
      100% {left:-120vw; top:-57vw;}
    }
    @keyframes glasses-small {
      0%   {left:-75vw; top:-54vw;}
      20%  {left:-81vw; top:-70vw;}
      40%  {left:-85vw; top:-90vw;}
      60%  {left:-90vw; top:-80vw;}
      80%  {left:-67vw; top:-66vw;}
      100% {left:-75vw; top:-54vw;}
    }

    @keyframes glasses-tall {
      0%   {left:-68vw; top:-51vw;}
      20%  {left:-75vw; top:-70vw;}
      40%  {left:-82vw; top:-79vw;}
      60%  {left:-86vw; top:-67vw;}
      80%  {left:-60vw; top:-61vw;}
      100% {left:-68vw; top:-51vw;}
    }

    @keyframes glasses {
      0%   {left:-65vw; top:-68vw;}
      20%  {left:-75vw; top:-79vw;}
      40%  {left:-79vw; top:-87vw;}
      60%  {left:-87vw; top:-78vw;}
      80%  {left:-56vw; top:-72vw;}
      100% {left:-65vw; top:-68vw;}
    }

    @keyframes glasses-xl {
      0%   {left:-50vw; top:-60vw;}
      20%  {left:-56vw; top:-68vw;}
      40%  {left:-61vw; top:-74vw;}
      60%  {left:-66vw; top:-67vw;}
      80%  {left:-40vw; top:-63vw;}
      100% {left:-50vw; top:-60vw;}
    }


@media screen and (max-width: 768px) {
  #header-container {
    height: 600px
  }
  #main-container {
    margin-top: 440px;
  }

  #header-container #main-jumbo h1 {
    font-size: 35px;
    line-height: 40px;
    text-align: left;
    
    &.culture {text-shadow: 1.5px 1.5px $tr-orange;}
    &.tourism {text-shadow: 1.5px 1.5px $tr-yellow;}
    &.sport {text-shadow: 1.5px 1.5px $tr-blue;}
    &.food {text-shadow: 1.5px 1.5px $tr-pink;}
  }

  .travel-tip-description {
    margin-top: 50px;
  }

  .travel-tip-description {
    margin: 0;

    &:first-child {
      margin-bottom: 15px;
    }
  }

  .travel-tip-detail-page-modal-body-map {
    width: 100%;
  }

}
.to-top-arrow.show {
  bottom: 60px;

  @media (max-width: 768px) {
    bottom: 40px;
  }
}
</style>

  