<template>
  <div class="col-12 col-md-5 col-lg-4 item-menu">
    <div class="detaileditem h-100">
      <div class="item-header-container">
        <div class="item-header" :class="{'border-top-0': index == 0}">
          <div class="d-flex align-items-center flex-wrap">
            <div v-if="itineraryItem.itineraryItemType == 'DEFAULT'" class="itinerary-item-icon m-0 mr-3"></div>
            <div v-else-if="itineraryItem.itineraryItemType == 'START'" class="itinerary-item-icon start m-0 mr-3"></div>
            <div v-else-if="itineraryItem.itineraryItemType == 'FINISH'" class="itinerary-item-icon finish m-0 mr-3"></div>
            <span>{{itineraryItem.itineraryItemType == 'DEFAULT' ? $t("general.itemOrder", [itemNo]) : itineraryItem.itineraryItemType == 'START' ? $t("general.startItem") : $t("general.finishItem") }}</span>
          </div>
          <p class="itinerary-item-info-row-item-under">{{getDifficulty()}}</p>
          <p v-if="itineraryItem.duration">{{ $t("public.travelTipDetailPage.itemEstimatedTime", [formatDuration(itineraryItem.duration)]) }}</p>
        </div>
        <div v-if="nextItemTransfers" class="transfers-container">
          <div class="vertical-dashed-line"></div>
          <div class="trip-icons">
            <div class="transfer-with-length-container" v-for="transfer in transferTypesUsed" :key="transfer.id">
              <div class="vertical-dashed-line short"></div>
              <div class="transfer-with-length">
                <img v-tooltip.top="findTransfer(transfer.type).text" :src="getImgUrl(transfer.type)" :alt="transfer.type">
                <div v-if="transfer.length" class="length">{{ Math.round(transfer.length * 10) / 10 }} km</div>
              </div>
              <div class="vertical-dashed-line short"></div>
            </div>
          </div>
          <div class="vertical-dashed-line"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import sharedUtils from '@/utils/sharedUtils'

    export default {
        name: "ItineraryItem",

        props: {
          itemNo: Number,
          itineraryItem: [Object],
          nextItemTransfers: Array,
          isMobileViewport: Boolean,
          index: Number
        },

        data() {
            return {
                transferTypesUsed: [],
                itineraryCollapsed: false,
                generatedId: null,
                totalLength: [],
            }
        },

        created: function() {
          this.formatTransferIcons();
        },

        computed: {
          transferTypes() {
            return [
              {value: 'WALK', text: this.$t("general.walk"), icon: 'icon-walking.svg'},
              {value: 'PUBLIC_TRANSPORT', text: this.$t("general.publicTransport"), icon: 'icon-public-transport.svg'},
              {value: 'BUS', text: this.$t("general.bus"), icon: 'icon-bus.svg'}, 
              {value: 'TRAIN', text: this.$t("general.train"), icon: 'icon-train.svg'},
              {value: 'BIKE', text: this.$t("general.bike"), icon: 'icon-bike.svg'},
              {value: 'ROLLER_SCATE', text: this.$t("general.rollerScate"), icon: 'icon-rollerscate.svg'},
              {value: 'SCOOTER', text: this.$t("general.scooter"), icon: 'icon-scooter.svg'},
              {value: 'BOAT', text: this.$t("general.boat"), icon: 'icon-boat.svg'},
              {value: 'CABLE_CAR', text: this.$t("general.cableCar"), icon: 'icon-cablecar.svg'},
              {value: 'CAR', text: this.$t("general.car"), icon: 'icon-car.svg'},
              {value: 'FLIGHT', text: this.$t("general.flight"), icon: 'icon-plane.svg'}
            ]
          },
        },

        methods: {
          formatDuration() {
            let duration = this.itineraryItem.duration
            if (duration < 60) return duration + ' min'
            else {
              const hours = Math.floor(duration / 60);
              const minutes = duration % 60;
              return minutes > 0 ? hours + ' h ' + minutes + ' min' : hours + ' h' 
            }
          },
          findTransfer(type) {
            return this.transferTypes.find(transfer => transfer.value == type)
          },
          getImgUrl(type) {
            let icon = this.findTransfer(type).icon
            return require(`@/assets/svg/${icon}`);
          },

          formatTransferIcons: function () {
              let transfersUsed = [];
              let length = 0
              if (!this.nextItemTransfers) return

              for(const [index, transferTypeValue] of this.nextItemTransfers.entries()) {
                if (!transfersUsed.some(transfer => transfer.transferType == transferTypeValue.transferType)) {
                  transfersUsed.push({id: index ,type: transferTypeValue.transferType, length: transferTypeValue.length})
                }
                length += transferTypeValue.length;
              }
              this.totalLength = length 
              this.transferTypesUsed = transfersUsed;
            },

          generateId: function() {
              return Math.floor((1 + Math.random()) * 0x10000)
                  .toString(16)
                  .substring(1);
          },

          getDifficulty() {
              return sharedUtils.getDifficultyTextFromValue(this.itineraryItem.difficulty)
          },

          sleep: async function(msec) {
              return new Promise(resolve => setTimeout(resolve, msec));
          },
        }

    }
</script>

<style scoped lang="scss">
    @import '@/scss/variables';


    .item-header {
      border-bottom: 1px solid $tr-light-gray;
      border-top: 1px solid $tr-light-gray;
      padding-block: 20px;
    }

    .detaileditem {
      max-width: 360px;
      text-align: left;
      background-color: $tr-white;
    }

    .vertical-dashed-line {
      border-left: #cfd8de 2px dashed;
      margin-left: 12px;
      height: 100%;

      &.short {
        height: 20px;
        margin-top: 2px;
      }
    }
    .trip-icons {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-bottom: 2px;

      .transfer-with-length {
        display: flex;
        align-items: center;
        margin-block: 5px;

        .length {
          margin-left: 20px;
          color: $tr-gray;
        }
      }
    }

    .itinerary-item-icon {
      background-image: url("data:image/svg+xml,%3Csvg fill='none' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='m17 26v8h-10v-8h2v1.5h2v-1.5h2v1.5h2v-1.5h2z' stroke='%23222'/%3E%3Cpath d='m32.5 11.482c0 0.4937-0.2332 1.1836-0.6478 1.9904-0.4063 0.7904-0.9534 1.6307-1.5086 2.4056-0.554 0.7731-1.1091 1.4715-1.5262 1.9772-0.1181 0.1431-0.2249 0.2706-0.3174 0.3798-0.0925-0.1092-0.1993-0.2367-0.3174-0.3798-0.4171-0.5057-0.9722-1.2041-1.5262-1.9772-0.5552-0.7749-1.1023-1.6152-1.5086-2.4056-0.4146-0.8068-0.6478-1.4967-0.6478-1.9904 0-2.1974 1.789-3.9821 4-3.9821s4 1.7847 4 3.9821z' stroke='%23222'/%3E%3Ccircle cx='29' cy='21' r='1' fill='%23222'/%3E%3Ccircle cx='29' cy='24' r='1' fill='%23222'/%3E%3Ccircle cx='29' cy='30' r='1' fill='%23222'/%3E%3Ccircle cx='29' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='21' cy='12' r='1' fill='%23222'/%3E%3Ccircle cx='26' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='18' cy='12' r='1' fill='%23222'/%3E%3Ccircle cx='29' cy='27' r='1' fill='%23222'/%3E%3Ccircle cx='23' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='15' cy='12' r='1' fill='%23222'/%3E%3Ccircle cx='20' cy='33' r='1' fill='%23222'/%3E%3Cpath d='m9.5 9.6009 2 1.3334v2.5657h-4v-2.5657l2-1.3334z' stroke='%23222'/%3E%3Ccircle cx='28.5' cy='11.5' r='1.5' stroke='%23222'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Crect width='40' height='40' fill='%23fff'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
      background-size: 40px 40px;
      background-repeat: no-repeat;
      background-position: center center;
      width: 50px;
      height: 50px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 51px;
      text-align: center;
      color: $tr-black;
      margin: 0 auto;

      &.start {
        background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='m16 22 13-5-13-5v10Zm0-12v24' stroke='%23222'/%3E%3Ccircle cx='25' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='22' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='19' cy='33' r='1' fill='%23222'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h40v40H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
      }
      &.finish {
        background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M34 12v10H16V12h18Zm-18-2v24' stroke='%23222'/%3E%3Ccircle cx='13' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='10' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='7' cy='33' r='1' fill='%23222'/%3E%3Cpath fill='%23222' d='M16 12h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm6-8h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm6-8h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h40v40H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
      }
    }
    .item-header-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-inline: 20px;

        p {
          margin-bottom: 0;
          color: $tr-gray; 
        }
    }

    .transfers-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .warning {
      border: dashed 3px #cfd8de;
      padding: 16px;
      white-space: pre-wrap;
    }

    .item-menu {
      filter: drop-shadow(rgba(0, 0, 0, 0.05) 0px 15px 10px);

      @media screen and (max-width: 768px) {
          display: none;
      }
    }
</style>
