<template>
  <div class="col-12 col-md-7 col-lg-7 offset-lg-1 col-travel-tip-description">
    <h2 class="itinerary-day-title" :id="'day-title' + itineraryDay.id" @click="activeparent($event)">{{itineraryDay.title ? itineraryDay.title : $t("admin.hereWillBe", [this.$t("inputLabels.dayTitle")])}}</h2>
    <b-collapse :id="'day-colapse' + itineraryDay.id" :visible="false">
      <article class="itinerary-day-description"><p>{{itineraryDay.description ? itineraryDay.description : $t("admin.hereWillBe", [this.$t("inputLabels.dayDescription")])}}</p></article>
    </b-collapse>
  </div>
</template>

<script>

    export default {
        name: "ItineraryDay",

        props: {
            itineraryDay: [Object],
        },

        created: function() {
          this.formatTransferIcons();
        },

        methods: {
            activeparent: function(event) {
              //Temporary fix for not being able to slide through carousel after first load
              //Emits to components/ImageCarousel
              this.$root.$emit("update-carousel")

              var elem = event.target.parentElement.parentElement;
              elem.classList.toggle('active');

              this.$root.$emit('bv::toggle::collapse', 'day-colapse' + this.itineraryDay.id)
                  },

            formatTransferIcons: function () {
              let transfersSet = new Set();

              if (this.itineraryDay.itineraryItems != undefined) {
                this.itineraryDay.itineraryItems.forEach(item => {
                  if (item.transferTypes && item.transferTypes.length > 0) {
                    item.transferTypes.forEach(type => {
                      transfersSet.add(type);
                    })
                  }
                })
              }
              this.transferTypes = transfersSet;
            },
        }

    }
</script>

<style scoped lang="scss">
    @import '@/scss/variables';

    .itinerary-day-title {
      scroll-margin-top: 30px;
    }

    .col-travel-tip-description{
      text-align: left;
      padding-top: 28px;
      margin-left: 0 !important;
      
      @media screen and (min-width: 768px) {
        border-bottom: 1px solid $tr-light-gray;
      }

      h2{
        text-align: left;
        display: inline-block;
        font-size: 25px;
        margin-bottom: 30px;
        cursor: pointer;

        &::after{
          content: "";
          display: inline-block;
          position: relative;
          width: 20px;
          height: 10px;
          margin-left: 30px;
          margin-bottom: 7px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          transition: transform 250ms ease-in-out;
        }
      }
    }
    
    .itinerary-day-description {
      margin-bottom: 100px;
      white-space: pre-wrap;

      @media screen and (max-width: 768px) {
        max-width: 570px;
        margin-inline: auto;
        margin-block: 20px 30px;
      }
    }

</style>
