<template>
  <div class="col-12 col-md-5 col-lg-4 sticky" style="filter: drop-shadow(rgba(0, 0, 0, 0.05) 0px 15px 10px)">
    <div class="tr-border-top-bottom tr-border tr-bottom-dashed-line h-100 daymenu">
      <div class="d-flex flex-wrap align-items-center">
        <div class="calendarWithNumber m-0 mr-3">{{dayNo}}</div ><span>{{ $t("general.dayOrder", [dayNo]) }}</span>
        <h2 @click="activeparent($event)" class="itinerary-day-title-mobile" :id="'day-title-mobile' + itineraryDay.id">{{itineraryDay.title ? itineraryDay.title : $t("admin.hereWillBe", [this.$t("inputLabels.dayTitle")])}}</h2>
      </div>
      <b-collapse :id="'day-colapse' + itineraryDay.id" :visible="false">
        <div class="day-info">
          <p v-if="transferTypesUsed.length > 0">{{ $t("public.travelTipDetailPage.transferBetweenItems") }}:</p>
          <div class="trip-icons">
            <div v-for="transfer in transferTypesUsed" :key="transfer.type">
              <img v-tooltip.top="findTransfer(transfer.type).text" :src="getImgUrl(transfer.type)" :alt="transfer.type">
              <div v-if="transfer.length" class="length">{{ Math.round(transfer.length) }}</div>
            </div>
          </div>
          <div class="itinerary-item-info-row-item">{{getDifficulty()}}</div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import sharedUtils from '../../utils/sharedUtils';

    export default {
        name: "ItineraryDay",

        props: {
            itineraryDay: [Object],
            dayNo: Number
        },

        data() {
            return {
                transferTypesUsed: [],
                itineraryCollapsed: false,
                generatedId: null,
            }
        },

        created: function() {
          this.formatTransferIcons();
        },

        computed: {
          transferTypes() {
            return [
              {value: 'WALK', text: this.$t("general.walk"), icon: 'icon-walking.svg'},
              {value: 'PUBLIC_TRANSPORT', text: this.$t("general.publicTransport"), icon: 'icon-public-transport.svg'},
              {value: 'BUS', text: this.$t("general.bus"), icon: 'icon-bus.svg'}, 
              {value: 'TRAIN', text: this.$t("general.train"), icon: 'icon-train.svg'},
              {value: 'BIKE', text: this.$t("general.bike"), icon: 'icon-bike.svg'},
              {value: 'ROLLER_SCATE', text: this.$t("general.rollerScate"), icon: 'icon-rollerscate.svg'},
              {value: 'SCOOTER', text: this.$t("general.scooter"), icon: 'icon-scooter.svg'},
              {value: 'BOAT', text: this.$t("general.boat"), icon: 'icon-boat.svg'},
              {value: 'CABLE_CAR', text: this.$t("general.cableCar"), icon: 'icon-cablecar.svg'},
              {value: 'CAR', text: this.$t("general.car"), icon: 'icon-car.svg'},
              {value: 'FLIGHT', text: this.$t("general.flight"), icon: 'icon-plane.svg'}
            ]
          },
        },

        methods: {
          findTransfer(type) {
            return this.transferTypes.find(transfer => transfer.value == type)
          },
          getImgUrl(type) {
            let icon = this.findTransfer(type).icon
            return require(`@/assets/svg/${icon}`);
          },
          getDifficulty() {
            return sharedUtils.getDifficultyTextFromItineraryItems(this.itineraryDay.itineraryItems)
          },

          activeparent: function(event) {
              //Temporary fix for not being able to slide through carousel after first load
              //Emits to components/ImageCarousel
              this.$root.$emit("update-carousel")
              
              var elem = event.target.parentElement.parentElement.parentElement.parentElement;
              elem.classList.toggle('active');

              this.$root.$emit('bv::toggle::collapse', 'day-colapse' + this.itineraryDay.id)
            },

            formatTransferIcons: function () {
              let transfersUsed = []
              let length = 0

              for(const item of this.itineraryDay.itineraryItems) {
                for (const transferTypeValue of item.transferTypeValues) {
                  if (!transfersUsed.some(transfer => transfer.type == transferTypeValue.transferType)) {
                    transfersUsed.push({type: transferTypeValue.transferType, length: 0})
                  }
                  let transfer = transfersUsed.find(transfer => transfer.type == transferTypeValue.transferType)
                  transfer.length += transferTypeValue.length
                  length += transferTypeValue.length;
                }
              }
              this.totalLength = length 
              this.transferTypesUsed = transfersUsed;
            },

            generateId: function() {
                return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
            },

            sleep: async function(msec) {
                return new Promise(resolve => setTimeout(resolve, msec));
            },
        }

    }
</script>

<style scoped lang="scss">
    @import '@/scss/variables';

    .itinerary-day-title-mobile {
      scroll-margin-top: 60px;
    }

    .calendarWithNumber{
      background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='10' cy='7' r='1' fill='%23222222'/%3E%3Ccircle cx='15' cy='7' r='1' fill='%23222222'/%3E%3Ccircle cx='20' cy='7' r='1' fill='%23222222'/%3E%3Ccircle cx='25' cy='7' r='1' fill='%23222222'/%3E%3Ccircle cx='30' cy='7' r='1' fill='%23222222'/%3E%3Cpath d='M35 7L35 35L5 35L5 7' stroke='%23222222'/%3E%3Cpath d='M5 11L35 11' stroke='%23222222'/%3E%3Cpath d='M9 30L31 30' stroke='%23222222'/%3E%3C/svg%3E%0A");
      background-size: 40px 40px;
      background-repeat: no-repeat;
      background-position: center center;
      width: 50px;
      height: 50px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 51px;
      text-align: center;
      color: $tr-black;
      margin: 0 auto;
    }

    h2 {
      text-align: left;
      display: inline-block;
      font-size: 25px;
      margin-bottom: 30px;
      cursor: pointer;

      &::after{
        content: "";
        display: inline-block;
        position: relative;
        width: 20px;
        height: 10px;
        margin-left: 30px;
        margin-bottom: 7px;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 2L12 12L2 2' stroke='%23222222' stroke-width='3'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        transition: transform 250ms ease-in-out;
      }
    }

    .day-info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-block: 20px;

      & > p {
        color: $tr-gray;
      }
    }

    .warning{
      padding-bottom: 10px;
    }

    .trip-icons {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      text-align: center;
      margin-bottom: 5px;

      .length {
        color: $tr-gray;
      }
    }

    .itinerary-item-info-row-item {
      color: $tr-gray;
      font-size: 15px;
      margin-bottom: 0;
    }
</style>
